const GEN_COOKIE_4AI_JS_URI = 'scripts/cookie_4ai.080b3905.js';
const GEN_COOKIE_4AI_CSS_URI = 'styles/cookie/cookie_4ai.0964d959.css';
const GEN_BOBBY_JS_URI = 'scripts/bobby.443bbace.js';
const GEN_WEBPUSH_JS_URI = 'scripts/webpush.0cb854f8.js';
const GEN_OFFLINE_JS_URI = 'scripts/offline.22b00d7b.js';

function loadWebPush() {
    return new loadExt([
        assetUrl(GEN_WEBPUSH_JS_URI)
    ], async () => {
        webPushInit();
    });
}

function loadServiceWorker() {
    const env = window.location.host === 'www.artwai.com' ? 'prod' : 'dev';
    return new loadExt([
        assetUrl(`lib/workbox/workbox-window.${env}.umd.js`),
        assetUrl(GEN_OFFLINE_JS_URI)
    ], async () => {
        if ('serviceWorker' in navigator) {
            const wb = new workbox.Workbox('/workbox_sw.js');

            wb.addEventListener('waiting', () => {
                new createUIPrompt(
                    'app-update',
                    'La PWA artwai.com doit effectuer une mise à jour.',
                    {
                        onAccept: async () => {
                            wb.messageSW({type: 'CLEAN_CACHE'});

                            wb.addEventListener('controlling', () => {
                                window.location.reload();
                            });

                            wb.messageSW({type: 'SKIP_WAITING'});
                        }
                    }).show();
            });

            wb.register();
            this.dispatchEvent(new CustomEvent('wbregistered', {detail: {workbox: wb}}));
        }
    });
}

window.addEventListener('load', async () => {
    if (is_service_worker_enabled()) {
        loadServiceWorker();
    }
});

window.addEventListener('load', async () => {
    if (is_webpush_available()) {
        loadWebPush();
    }
});

window.addEventListener('load', async () => {
    if (!('indexedDB' in window)) {
        a4ilog.warn('This browser doesn\'t support IndexedDB. Perfomances may be impacted');
        return;
    }

    const db_name = 'memshare';
    const db_version = 2;

    let idb = window.indexedDB;

    // Open a db instance
    let request = idb.open(db_name, db_version);

    request.onerror = (event) => a4ilog.error('Database error: ' + event.target.errorCode);

    // Create or update the database structure
    request.onupgradeneeded = (event) => {
        const db = event.target.result;

        const offlineStore = db.createObjectStore(db_name, {keyPath: 'key'});
        offlineStore.createIndex('value', 'value');
    };

    request.onsuccess = (event) => {
        // Get db instance
        const db = event.target.result;

        // Open a transaction
        const transaction = db.transaction([db_name], 'readwrite');

        // Select offline-pages object store (table)
        const memshare = transaction.objectStore(db_name);
        const connected = memshare.get('connected');

        connected.onsuccess = () => {
            const connected = document.cookie.search('user_connected') >= 0;
            memshare.put({key: 'connected', value: connected});
        }
    };
});